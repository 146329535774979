import source from "@/axios";

export default {
    namespaced: true,
    state: {
        countries: [],
        platform: [],
        os: [],
        managers: []
    },
    actions: {
        load(context) {
            source.get("/users/me/managers").then(response => {
                context.state.managers = response.data;
            });
            source.get("/countries", { params: { filter: { order: "name asc" } } }).then(response => {
                context.state.countries = response.data.map(el => {
                    return { text: el.name, value: el.code };
                });
            });
            source.get("/os", { params: { filter: { order: "name asc" } } }).then(response => {
                context.state.os = response.data
                    .sort((a, b) => {
                        if (a.name.toLowerCase() > b.name.toLowerCase()) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    .map(el => {
                        return { text: el.name + " " + el.version, value: el.id };
                    });
            });
            source
                .get("/platforms/wm", {
                    params: {
                        filter: {
                            limit: 20,
                            attributes: ["id", "name"]
                        }
                    }
                })
                .then(response => {
                    context.state.platform = response.data.map(el => {
                        return { text: el.name, value: el.id };
                    });
                });
        }
    }
};
