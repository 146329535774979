const authTokens = { dev: 'affilightToken', prod: 'm10Token' };

// APP CONFIG
export const $themeConfig = {
    lang: 'en', // en, da, de, el, es, fr, hu, it, ja, pl, pt, ru, sv, tr, zh
    // theme: 'light',
    theme: 'dark',
    // light, dark, system
    // navigation: 'vertical',
    navigation: 'horizontal',
    // vertical, collapsible-vertical, horizontal
    layout: 'full', // full, boxed-layout, large-boxed-layout
    // aff id
    affId: process.env.VUE_APP_AFF_ID,
    baseURL: window.location.host.indexOf("admin.") == 0 ? window.location.host.split("admin.")[1] : window.location.host,
    baseApiURL: window.location.host,
    authToken: (window.location.host.indexOf("dev.") !== -1 || window.location.host.indexOf("localhost") == 0) ? authTokens.dev : authTokens.prod
};
