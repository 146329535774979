import moment from 'moment';
import store from '@/store'

if(document.domain.indexOf('localhost') > -1){
    window.dataLayer = {
        push: function(value){
            console.log(value)
        }
    }
}

const isDataLayerDefined = 'dataLayer' in window && typeof window.dataLayer.push === 'function';

function sendNavigationChange() {
    const { href } = window.location;
    const path = href.match(/^https?:\/\/[a-zA-Z0-9:.]+(\/.+)$/);
    const page = path && path.length > 0 ? path[1] : '/';
    window.dataLayer.push({ event: 'pageChange', location: href, page: page });
}

export default {
    send(signal) {
        if (isDataLayerDefined) {
            switch (signal) {
                case "platformCreate":
                    window.dataLayer.push({ event: 'platformCreate' });
                    break;
                case "userLogin":
                    window.dataLayer.push({
                        id: store.state.account.id,
                        name: store.state.account.name,
                        email: store.state.account.email,
                        ip: store.state.account.ip,
                        activity: store.state.account.activity,
                        userId: store.state.account.user_id,
                        role: store.state.account.role.name,
                        mid: store.state.account.owner && store.state.account.owner.emp_id ? store.state.account.owner.emp_id : 0,
                        reg_date: moment(store.state.account.created_at).utc().unix()
                    })
                    break;
                case "navigationChange":
                    sendNavigationChange()
                    break;
            }
        }
    }
}