import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/index.vue";
import Admin from "../views/admin.vue";
import store from "@/store";
import source from "@/axios";
import metrics from "@/metrics";
import appSettings from "@/app-setting";
import { escapeHTMLcharset } from '@/helpers'
import { update } from '@intercom/messenger-js-sdk';

Vue.use(VueRouter);

function checkAuth() {
    /* 1 - Start Development */
    // return new Promise((resolve, reject) => {
    //     if (store.getters["isLogged"]) {
    //         resolve();
    //     }

    //     let token = escapeHTMLcharset(localStorage.getItem('affauthToken'));
    //     source.defaults.headers.common["Authorization"] = "Bearer " + token;
    //     source
    //         .get("/users/me")
    //         .then(response => {
    //             store.dispatch("login", response.data);
    //             metrics.send("userLogin");
    //             resolve();
    //         })
    //         .catch(() => {
    //             store.dispatch("logout");
    //             reject();
    //         });
    // });
    /* End Development */

    return new Promise((resolve, reject) => {
        if (store.getters["isLogged"]) {
            resolve();
        }
        if (!Vue.$cookies.get(appSettings.authToken)) {
            reject();
        } else {
            if (Vue.$cookies.get(appSettings.authToken)) {
                let token = Vue.$cookies.get(appSettings.authToken);
                token = escapeHTMLcharset(token);
                source.defaults.headers.common["Authorization"] = "Bearer " + token['id'];
            }
            source
                .get("/users/me")
                .then(response => {
                    store.dispatch("login", response.data);
                    metrics.send("userLogin");
                    resolve();
                })
                .catch(() => {
                    store.dispatch("logout");
                    reject();
                });
        }
    });
}

const routes = [
    //dashboard
    // {
    //     path: "/",
    //     name: "Home",
    //     component: Home,
    //     meta: { layout: "landing" },
    //     beforeEnter: (to, from, next) => {
    //         checkAuth()
    //             .then(() => {
    //                 router.push({ name: "dashboard" });
    //             })
    //             .catch(() => {
    //                 next();
    //             });
    //     }
    // },
    {
        path: "/",
        component: Admin,
        beforeEnter: (to, from, next) => {
            checkAuth()
                .then(() => {
                    next();
                })
                .catch(() => {
                    router.push({ name: "login" });
                });
        },
        children: [
            {
                path: "",
                name: "dashboard",
                component: () => import("../views/pages/Dashboard.vue")
            },
            {
                path: "statistics",
                name: "statistics",
                component: () => import("../views/pages/Statistics.vue")
            },
            {
                path: "promo",
                name: "promos",
                component: () => import("../views/pages/Promos.vue")
            },
            {
                path: "promo/:id",
                name: "promo",
                component: () => import("../views/pages/Promo.vue")
            },
            {
                path: "payments",
                name: "payments",
                component: () => import("../views/pages/Payments.vue")
            },
            {
                path: "finances",
                name: "finances",
                component: () => import("../views/pages/Finances.vue")
            },
            {
                path: "contacts",
                name: "contacts",
                component: () => import("../views/pages/Contacts.vue")
            },
            {
                path: "settings",
                name: "settings",
                component: () => import("../views/pages/Settings.vue")
            },
            //users
            {
                path: "profile",
                name: "profile",
                component: () => import(/* webpackChunkName: "users-profile" */ "../views/users/profile.vue")
            },
            {
                path: "profile-setting",
                name: "account-setting",
                component: () => import(/* webpackChunkName: "users-account-setting" */ "../views/users/account_setting.vue")
            }
        ]
    },
    {
        path: "/logout",
        name: "logout",
        beforeEnter: (to, from, next) => {
            store.dispatch("logout");
            next("/login");
        },
        meta: { layout: "auth" }
    },
    {
        path: "/dashboard",
        redirect: "/"
    },
    {
        name: "login",
        path: "/login",
        beforeEnter() {
            window.location.href = window.location.protocol + "//" + appSettings.baseURL + "/admin/#?action=wm-signin"
        }
    },
    {
        path: "/register",
        beforeEnter() {
            window.location.href = window.location.protocol + "//" + appSettings.baseURL + "/admin/#?action=wm-signup"
        }
    },
    {
        path: "/pass-recovery",
        beforeEnter() {
            window.location.href = window.location.protocol + "//" + appSettings.baseURL + "/#?action=wm-password-recovery"
        }
    },
    /* 2 - Start Development */
    //AUTH
    // {
    //     path: "/login",
    //     name: "login",
    //     component: () => import(/* webpackChunkName: "auth-login" */ "../views/auth/login.vue"),
    //     meta: { layout: "auth" }
    // },
    // {
    //     path: "/register",
    //     name: "register",
    //     component: () => import(/* webpackChunkName: "auth-register" */ "../views/auth/register.vue"),
    //     meta: { layout: "auth" }
    // },
    // {
    //     path: "/contact-managers",
    //     name: "lockscreen",
    //     component: () => import(/* webpackChunkName: "auth-lockscreen" */ "../views/auth/lockscreen.vue"),
    //     meta: { layout: "auth" }
    // },
    // {
    //     path: "/pass-recovery",
    //     name: "pass-recovery",
    //     component: () => import(/* webpackChunkName: "auth-pass-recovery" */ "../views/auth/pass_recovery.vue"),
    //     meta: { layout: "auth" }
    // },
    /* End Development */
    {
        path: "*",
        name: "error404",
        component: () => import(/* webpackChunkName: "pages-error404" */ "../views/pages/error404.vue"),
        meta: { layout: "auth" }
    }

];

const router = new VueRouter({
    mode: "history",
    linkExactActiveClass: "active",
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    }
});

router.beforeEach((to, from, next) => {
    metrics.send("navigationChange")
    if (to.meta && to.meta.layout) {
        store.commit("setLayout", to.meta.layout);
    } else {
        store.commit("setLayout", "app");
    }

    // update Intercom to log an impression at the current URL
    update();

    next(true);
});

export default router;

export function parseURL(query) {
    let queryObject = query.slice(2).split("&");
    let result = {};
    for (let part of queryObject) {
        part = part.split("=");
        result[part[0]] = part[1];
    }
    return result;
}
