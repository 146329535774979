import axios from "axios";
import appSettings from "@/app-setting";

let source = axios.create({});
source.defaults.baseURL = window.location.protocol + "//" + appSettings.baseApiURL + "/api/";

/* 4 - Start Development */
// source.defaults.baseURL = "https://m10.dev.trafficgiraffic.com/api/";
/* End Development */

source.getCaptcha = function () {
    return source.get("/captcha", {
        params: {
            aff_id: process.env.VUE_APP_AFF_ID,
            role: 'webmaster',
            role_id: 3,
            type: 'signup'
        }
    })
};

export default source;
