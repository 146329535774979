<template>
    <!--  BEGIN SIDEBAR  -->
    <div class="sidebar-wrapper sidebar-theme">
        <nav ref="menu" id="sidebar">
            <div class="shadow-bottom"></div>

            <perfect-scrollbar
                class="list-unstyled menu-categories"
                tag="ul"
                :options="{
                    wheelSpeed: 0.5,
                    swipeEasing: !0,
                    minScrollbarLength: 40,
                    maxScrollbarLength: 300,
                    suppressScrollX: true
                }"
            >
                <router-link tag="li" :to="{ name: 'dashboard' }" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trending-up"
                            >
                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                            </svg>
                            <span>Dashboard</span>
                        </div>
                    </a>
                </router-link>
                
                <router-link tag="li" :to="{ name: 'statistics' }" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trending-up"
                            >
                                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                <polyline points="17 6 23 6 23 12"></polyline>
                            </svg>
                            <span>Statistics</span>
                        </div>
                    </a>
                </router-link>

                <router-link tag="li" :to="{ name: 'promos' }" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-cpu"
                            >
                                <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="10"/>
                                <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="6"/>
                                <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="2"/>
                            </svg>
                            <span>Promo</span>
                        </div>
                    </a>
                </router-link>

                <router-link tag="li" :to="{ name: 'finances' }" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-layout"
                            >
                                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                <line x1="3" y1="9" x2="21" y2="9"></line>
                                <line x1="9" y1="21" x2="9" y2="9"></line>
                            </svg>
                            <span>Finances</span>
                        </div>
                    </a>
                </router-link>

                <router-link tag="li" :to="{ name: 'payments' }" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-dollar-sign"
                            >
                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                            </svg>
                            <span>Payments</span>
                        </div>
                    </a>
                </router-link>

                <router-link tag="li" :to="{ name: 'contacts' }" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-home"
                            >
                                <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="4"/>
                                <path xmlns="http://www.w3.org/2000/svg" d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"/>
                            </svg>
                            <span>Contacts</span>
                        </div>
                    </a>
                </router-link>

                <router-link tag="li" :to="{name: 'profile'}"  class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="css-i6dzq1"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            <span>Profile</span>
                        </div>
                    </a>
                </router-link>

                <b-dropdown-divider></b-dropdown-divider>

                <router-link tag="li" to="/logout" class="menu" @click.native="toggleMobileMenu">
                    <a class="dropdown-toggle">
                        <div class="">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            <span>Sign out</span>
                        </div>
                    </a>
                </router-link>

            </perfect-scrollbar>
        </nav>
    </div>
    <!--  END SIDEBAR  -->
</template>
<script>
    export default {
        data() {
            return { menu_collapse: "dashboard" };
        },

        watch: {
            $route(to) {
                let selector;
                if (this.$route.params.id) {
                    selector = document.querySelector('#sidebar a[href="/promos"]');
                } else {
                    selector = document.querySelector('#sidebar a[href="' + to.path + '"]');
                }
                if (selector) {
                    const ul = selector.closest("ul.collapse");
                    if (!ul) {
                        const ele = document.querySelector(".dropdown-toggle.not-collapsed");
                        if (ele) {
                            ele.click();
                        }
                    }
                }
            }
        },

        mounted() {
            // default menu selection on refresh
            let selector;
            if (this.$route.params.id) {
                selector = document.querySelector('#sidebar a[href="/promos"]');
            } else {
                selector = document.querySelector('#sidebar a[href="' + window.location.pathname + '"]');
            }
            if (selector) {
                const ul = selector.closest("ul.collapse");
                if (ul) {
                    let ele = ul.closest("li.menu").querySelectorAll(".dropdown-toggle");
                    if (ele) {
                        ele = ele[0];
                        setTimeout(() => {
                            ele.click();
                        });
                    }
                } else {
                    selector.click();
                }
            }
        },

        methods: {
            toggleMobileMenu() {
                if (window.innerWidth < 991) {
                    this.$store.commit("toggleSideBar", !this.$store.state.is_show_sidebar);
                }
            }
        }
    };
</script>
