<template>
  <!-- BEGIN FOOTER -->
  <div class="footer-wrapper flex-wrap">
    <div class="footer-section f-section-1">
      <p class="">Copyright © {{ currentYear }} <a target="_blank" :href="baseURL + '/'">Mobile 10</a>, All rights reserved.</p>
    </div>

    <div class="footer-section f-section-2 d-flex">
      <div class="mr-4">
        <a target="_blank" :href="baseURL + '/policy-en.html'">Privacy policy</a>
      </div>
      <div>
        <a target="_blank" :href="baseURL + '/agreement-en.html'">Terms and cond.</a>
      </div>
    </div>
  </div>
  <!-- END FOOTER -->
</template>
<script>
import appSettings from "@/app-setting";
export default {
  data() {
    return {
      baseURL: window.location.protocol + '//' + appSettings.baseURL,
      currentYear: "2022",
    };
  },
  mounted() {
    this.currentYear = new Date().getFullYear();
  },
  methods: {},
};
</script>
