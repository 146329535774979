<template>
    <div class="layout-px-spacing dash_1">
        <portal to="breadcrumb">
            <ul class="navbar-nav flex-row">
                <li>
                    <div class="page-header">
                        <nav class="breadcrumb-one" aria-label="breadcrumb">
                            <ol class="breadcrumb">
                                <li class="breadcrumb-item"><a href="javascript:;">Dashboard</a></li>
                                <li class="breadcrumb-item active" aria-current="page"><span>Sales</span></li>
                            </ol>
                        </nav>
                    </div>
                </li>
            </ul>

            <ul class="navbar-nav flex-row ml-auto">
                <li class="nav-item more-dropdown">
                    <b-nav-dropdown ref="ddl_settings" right toggle-tag="a" class="custom-dropdown-icon" variant="normal">
                        <template #button-content>
                            <span>Settings</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-chevron-down"
                            >
                                <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                        </template>
                <li><a class="dropdown-item" data-value="Settings" href="javascript:void(0);" @click="$refs.ddl_settings.hide()">Settings</a></li>
                <li><a class="dropdown-item" data-value="Mail" href="javascript:void(0);" @click="$refs.ddl_settings.hide()">Mail</a></li>
                <li><a class="dropdown-item" data-value="Print" href="javascript:void(0);" @click="$refs.ddl_settings.hide()">Print</a></li>
                <li><a class="dropdown-item" data-value="Download" href="javascript:void(0);" @click="$refs.ddl_settings.hide()">Download</a></li>
                <li><a class="dropdown-item" data-value="Share" href="javascript:void(0);" @click="$refs.ddl_settings.hide()">Share</a></li>
                </b-nav-dropdown>
                </li>
            </ul>
        </portal>
        <router-view>
            <!-- BEGIN LOADER -->
            <div id="load_screen">
                <div class="loader">
                    <div class="loader-content">
                        <div class="spinner-grow align-self-center"></div>
                    </div>
                </div>
            </div>
            <!--  END LOADER -->
        </router-view>
        
    </div>
</template>

<script>

    export default {
        data() {
            return {}
        },
        computed: {
        },
        mounted() {},
        methods: {}
    };
</script>
