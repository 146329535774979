export function escapeHTMLcharset(value) {
    if (typeof value === 'string') {
        try {
            return JSON.parse(value)
        } catch (e) {
            return escapeHTMLcharset(value
                .replaceAll('%25', '%')
                .replaceAll('%22', '"')
                .replaceAll('%7D', '}')
                .replaceAll('%3A', ':')
                .replaceAll('%7B', '{')
                .replaceAll('%2C', ','))
        }
    } else {
        return value
    }
}