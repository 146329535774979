<template>
    <div>
        <!--  BEGIN NAVBAR  -->
        <div class="header-container fixed-top">
            <header class="header navbar navbar-expand-sm">
                <ul class="navbar-item theme-brand flex-row text-center">
                    <li class="nav-item theme-logo">
                        <router-link :to="{ name: 'dashboard' }">
                            <div class="navbar-logo" alt="logo"></div>
                        </router-link>
                    </li>
                    <li class="nav-item d-none d-lg-block">
                        <b-button variant="outline-primary" class="mt-1" :to="{ name: 'promo', params: { id: 'add' } }">
                            <svg
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="css-i6dzq1"
                            >
                                <line x1="12" y1="5" x2="12" y2="19"></line>
                                <line x1="5" y1="12" x2="19" y2="12"></line>
                            </svg>
                            ADD PROMO LINK
                        </b-button>
                    </li>
                </ul>
                <div class="d-none horizontal-menu">
                    <a
                        class="sidebarCollapse"
                        data-placement="bottom"
                        @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-menu"
                        >
                            <line x1="3" y1="12" x2="21" y2="12"></line>
                            <line x1="3" y1="6" x2="21" y2="6"></line>
                            <line x1="3" y1="18" x2="21" y2="18"></line>
                        </svg>
                    </a>
                </div>

                <div class="navbar-item flex-row ml-sm-auto">
                    <div class="d-flex flex-column mr-4">
                        <p class="m-0 text-center"><small>Balance</small></p>
                        <p class="m-0 text-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-dollar-sign"
                                style="margin-top: -2px;"
                            >
                                <line x1="12" y1="1" x2="12" y2="23"></line>
                                <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                            </svg>
                            <strong class="ml-1">{{ balance | toCurrency }}</strong>
                        </p>
                    </div>

                    <div class="d-none d-sm-flex flex-column mr-0" v-if="time">
                        <p class="m-0 text-center"><small>Server time</small></p>
                        <p class="m-0 text-center">
                            <svg
                                viewBox="0 0 24 24"
                                width="16"
                                height="16"
                                stroke="currentColor"
                                stroke-width="2"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="css-i6dzq1"
                                style="margin-top: -3px;"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            <strong class="ml-1">{{ time }}</strong>
                        </p>
                    </div>
                    <div class="dark-mode d-flex align-items-center" v-if="false">
                        <a v-if="$store.state.dark_mode == 'light'" class="d-flex align-items-center" @click="toggleMode('dark')">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-sun"
                            >
                                <circle cx="12" cy="12" r="5"></circle>
                                <line x1="12" y1="1" x2="12" y2="3"></line>
                                <line x1="12" y1="21" x2="12" y2="23"></line>
                                <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                                <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                                <line x1="1" y1="12" x2="3" y2="12"></line>
                                <line x1="21" y1="12" x2="23" y2="12"></line>
                                <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                                <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                            </svg>
                            <span class="ml-2">Light</span>
                        </a>
                        <a
                            v-if="$store.state.dark_mode == 'dark'"
                            class="d-flex align-items-center"
                            @click="toggleMode('system')"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-moon"
                            >
                                <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
                            </svg>
                            <span class="ml-2">Dark</span>
                        </a>
                        <a
                            v-if="$store.state.dark_mode == 'system'"
                            class="d-flex align-items-center"
                            @click="toggleMode('light')"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-airplay"
                            >
                                <path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1"></path>
                                <polygon points="12 15 17 21 7 21 12 15"></polygon>
                            </svg>
                            <span class="ml-2">System</span>
                        </a>
                    </div>

                    <b-dropdown
                        toggle-tag="a"
                        variant="icon-only"
                        toggle-class="user nav-link"
                        class="nav-item user-profile-dropdown"
                        :right="true"
                    >
                        <template #button-content>
                            <Avatar />
                        </template>

                        <b-dropdown-item :to="{ name: 'profile' }">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-user"
                            >
                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                <circle cx="12" cy="7" r="4"></circle>
                            </svg>
                            Profile
                        </b-dropdown-item>

                        <b-dropdown-divider></b-dropdown-divider>

                        <b-dropdown-item to="/logout">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-log-out"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                            Sign Out
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN NAVBAR  -->
        <div class="sub-header-container">
            <header class="header navbar navbar-expand-sm">
                <a
                    class="sidebarCollapse"
                    data-placement="bottom"
                    @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-menu"
                    >
                        <line x1="3" y1="12" x2="21" y2="12"></line>
                        <line x1="3" y1="6" x2="21" y2="6"></line>
                        <line x1="3" y1="18" x2="21" y2="18"></line>
                    </svg>
                </a>

                <!-- Portal vue for Breadcrumb -->
                <portal-target name="breadcrumb"></portal-target>
            </header>
        </div>
        <!--  END NAVBAR  -->
        <!--  BEGIN TOPBAR  -->
        <div class="topbar-nav header navbar" role="banner">
            <nav class="topbar">
                <ul class="list-unstyled menu-categories" id="topAccordion">
                    <li class="menu single-menu">
                        <router-link
                            :to="{ name: 'dashboard' }"
                            data-toggle="collapse"
                            aria-expanded="true"
                            class="dropdown-toggle autodroprown absolute-exact"
                        >
                            <div class="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
                                    <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                    <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                </svg>
                                <span>Dashboard</span>
                            </div>
                        </router-link>
                    </li>
                    
                    <li class="menu single-menu">
                        <router-link
                            :to="{ name: 'statistics' }"
                            data-toggle="collapse"
                            aria-expanded="true"
                            class="dropdown-toggle autodroprown"
                        >
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trending-up"
                                >
                                    <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                                    <polyline points="17 6 23 6 23 12"></polyline>
                                </svg>
                                <span>Stat</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="menu single-menu">
                        <router-link
                            :to="{ name: 'promos' }"
                            data-toggle="collapse"
                            aria-expanded="true"
                            class="dropdown-toggle autodroprown"
                        >
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-cpu"
                                >
                                    <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="10"/>
                                    <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="6"/>
                                    <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="2"/>
                                </svg>
                                <span>Promo</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="menu single-menu">
                        <router-link
                            :to="{ name: 'finances' }"
                            data-toggle="collapse"
                            aria-expanded="true"
                            class="dropdown-toggle autodroprown"
                        >
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-layout"
                                >
                                    <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="3" y1="9" x2="21" y2="9"></line>
                                    <line x1="9" y1="21" x2="9" y2="9"></line>
                                </svg>
                                <span>Finances</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="menu single-menu">
                        <router-link
                            :to="{ name: 'payments' }"
                            data-toggle="collapse"
                            aria-expanded="true"
                            class="dropdown-toggle autodroprown"
                        >
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-dollar-sign"
                                >
                                    <line x1="12" y1="1" x2="12" y2="23"></line>
                                    <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                                </svg>
                                <span>Payments</span>
                            </div>
                        </router-link>
                    </li>

                    <li class="menu single-menu">
                        <router-link
                            :to="{ name: 'contacts' }"
                            data-toggle="collapse"
                            aria-expanded="true"
                            class="dropdown-toggle autodroprown"
                        >
                            <div class="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-home"
                                >
                                    <circle xmlns="http://www.w3.org/2000/svg" cx="12" cy="12" r="4"/>
                                    <path xmlns="http://www.w3.org/2000/svg" d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"/>
                                </svg>
                                <span>Contacts</span>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </nav>
        </div>
        <!--  END TOPBAR  -->
    </div>
</template>
<script>
    import store from "@/store";
    import source from "@/axios";
    import Avatar from "@/views/users/avatar";
    import { Intercom } from "@intercom/messenger-js-sdk";
    Intercom({
        app_id: process.env.VUE_APP_INTERCOM_APP_ID,
    });

    export default {
        components: { Avatar },
        data() {
            return {
                selectedLang: null,
                countryList: this.$store.state.countryList,
                time: 0
            };
        },
        mounted() {
            this.selectedLang = this.$appSetting.toggleLanguage();
            this.toggleMode();
            this.getServerTime();
        },
        computed: {
            account() {
                return store.state.account ?? {};
            },
            balance() {
                return store.getters["balance"]?.result;
            },
            isLogged() {
                return store.state.isLogged;
            }
        },
        methods: {
            toggleMode(mode) {
                this.$appSetting.toggleMode(mode);
            },

            changeLanguage(item) {
                this.selectedLang = item;
                this.$appSetting.toggleLanguage(item);
            },
            getServerTime() {
                source.get("/time").then(response => {
                    this.time = new Date(response.data.time).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZone: "Europe/London"
                    });
                });
                setTimeout(() => {
                    if (this.isLogged) {
                        this.getServerTime();
                    }
                }, 1000 * 60);
            }
        }
    };
</script>
