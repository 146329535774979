<template>
    <b-avatar v-if="image !== null" :src="image" class="avatar-xl mr-1"></b-avatar>
    <b-avatar v-else>
        <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="person fill" aria-hidden="true" alt="avatar" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-person-fill b-icon bi"><g><path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path></g></svg>
    </b-avatar>
</template>
<script>
    import store from "@/store"
    export default ({
        name: "Avatar",
        computed: {
            image(){
                return store.state.account?.avatar?.src
            }
        }
    })
</script>
